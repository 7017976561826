import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import GoogleMapReact from 'google-map-react';
import classNames from 'classnames'


/**
 * Components
 */
import { Sort, Map, Alert } from '@components/icon/icon';
import PropertyBoxRow from '@components/property-box-row/property-box-row';
import Marker from '@components/marker/marker';

/**
 * Assets
 */
import './styles/_index.scss';

const PropertiesList = ({properties}) => {
    const [mapActive, setMapActive] = useState(false);
    const handleMapToggle = (e) => {
        e.preventDefault();
        
        setMapActive(!mapActive);
    }
    return (
        <div className="properties-list">
            <Container>
                <Row className="search-top justify-content-lg-between">
                    <Col md="12" lg="auto">
                        <p><strong>{properties.length}</strong> Properties for sale in Crouch End, London</p>
                    </Col>
                    <Col md="12" lg="auto">
                        <ul className="utils-nav d-flex justify-content-lg-end">
                            <li>
                                <a href="/"><Sort /> Sort</a>
                            </li>
                            <li>
                                <a href="/" onClick={handleMapToggle}><Map /> Map</a>
                            </li>
                            <li>
                                <Alert /> Never miss a property! <a href="/">Create Alert</a>
                            </li>
                        </ul>
                    </Col>
                </Row>
                <div className={classNames('properties-map', {'d-none': !mapActive})}>
                    <GoogleMapReact
                        yesIWantToUseGoogleMapApiInternals 
                        bootstrapURLKeys={{ 
                            key: process.env.GOOGLE_API,
                            libraries:['places', 'geometry']
                        }}
                        defaultCenter={{
                            lat: properties[0] && properties[0].lat,
                            lng: properties[0] && properties[0].lng,
                        }}
                        defaultZoom={8}
                    >
                        {properties.map((marker,ind) => <Marker key={ind} lat={marker.lat} lng={marker.lng} text={marker.title} />)}
                    </GoogleMapReact>
                </div>
                {properties.map((item, ind) => (
                    <PropertyBoxRow key={ind} property={item} />
                ))}
            </Container>
        </div>
    )
}

export default PropertiesList
